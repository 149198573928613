import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Button from '../components/button'

class IndexPage extends React.Component {
  render () {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title='Inicial'
          keywords={[`website`, `gatsby`, `javascript`, `react`]}
        />
        <img
          style={{ margin: 0 }}
          src='./backgroundScene.jpeg'
          alt='Background'
        />
        <h1>
          Olá pessoal{' '}
          <span role='img' aria-label='wave emoji'>
            👋
          </span>
        </h1>
        <p>
          Bem-vindo ao meu mais novo site, construído com{' '}
          <strong>Gatsby.js</strong>. Neste momento você encontra-se na página
          inicial.
        </p>
        <p>
          Neste blog, me esforçarei para estar constantemente postando artigos
          sobre meu dia a dia, projetos pessoais, trabalho e estudo.
        </p>
        <p>Então, vamos lá!</p>
        <Link to='/blog/'>
          <Button marginTop='35px'>Publicações</Button>
        </Link>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
